<template>
    <wizard-layout>
        <div class="wizard-agreement">
            <div class="wizard__title">
                <div class="wizard__title-wrapper">
                    <div class="wizard-agreement__text">
                        Freelance writer agreement
                    </div>
                    <img
                        src="@/assets/img/svg/icon-extension.svg"
                        alt="icon extension"
                        style="cursor: pointer"
                        @click="openWizardModal('Freelance writer agreement')"
                    >
                </div>
            </div>
            <div class="page-agreements wizard__form">
                <agreements />
            </div>
            <div class="wizard-content__buttons wizard-content__buttons--agreement">
                <custom-button
                    default
                    class="button btn-base_colored sort-button"
                    @on-btn-click="$emit('goToPrevStep', $event)"
                >
                    Previous
                </custom-button>
                <custom-button
                    default
                    @on-btn-click="$emit('goToNextStep')"
                >
                    Accept
                </custom-button>
            </div>
        </div>
        <wizard-modal ref="wizard-modal">
            <template slot="wizard-content">
                <agreements />
                <div class="wizard-content__buttons wizard-content__buttons--agreement">
                    <custom-button
                        default
                        class="button btn-base_colored sort-button"
                        @on-btn-click="$emit('goToPrevStep', $event)"
                    >
                        Previous
                    </custom-button>
                    <custom-button
                        default
                        @on-btn-click="$emit('goToNextStep')"
                    >
                        Accept
                    </custom-button>
                </div>
            </template>
        </wizard-modal>
    </wizard-layout>
</template>

<script>
import Agreements from '@/components/common/Agreements';
import WizardModal from '@/components/modals/WizardModal';

export default {
    name: 'Index',
    metaInfo: {
        title: 'Wizard Agreement'
    },
    components: {
        Agreements,
        WizardModal
    },
    methods: {
        openWizardModal(title) {
            this.$refs['wizard-modal'].open(title)
        }
    }
}
</script>

<style lang="scss">
    .wizard-agreement {
        &__content--list {
            margin-bottom: 40px;
        }
        &__wrapper {
            padding: 0 30px;
            margin-bottom: 30px;
            @media screen and (max-width: 550px){
                padding: 0;
            }
        }
        &__title {
            font-weight: bold;
            font-size: 18px;
            margin-top: 30px;
        }
        &__text {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
        }
        .wizard__form{
            padding-bottom: 0;
        }
    }

    .page-agreements{
        height: 700px;
        overflow-y: scroll;
    }
    .wizard-content__buttons--agreement{
        padding: 20px;
        border-top: 1px solid #EAEAEA;
    }

    .wizard__title-wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>
