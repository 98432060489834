<template>
    <div
        v-if="dialog"
        class="wizard-modal"
        @click.self="close()"
    >
        <div class="wizard-modal__dialog">
            <div
                style="background-color: white;"
                :class="{'height-initial': $route.name === 'wizard-faq' }"
            >
                <header class="wizard-modal__header">
                    <h2>{{ title }}</h2>
                    <span
                        class="wizard-modal__close"
                        @click="close()"
                    />
                </header>
                <slot name="wizard-content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WizardModal',
    data: () => ({
        dialog: false,
        title: null
    }),
    methods: {
        open(title) {
            this.dialog = true
            this.title = title
        },
        close() {
            this.dialog = false
        }
    }
}
</script>

<style lang="scss">
.wizard-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow-y: scroll;

    &__dialog {
        max-width: 1440px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        .agreement-wrapper {
            max-width: 100%;
            height: 100%;
            overflow: initial;
        }
    }
    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 20px 30px;
        position: relative;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        min-width: 14px;
        width: 14px;
        height: 14px;
        background: url("~@/assets/img/svg/close.svg") no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        color: #4F4F4F;
        cursor: pointer;
    }
}

.height-initial{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    div {
        background-color: white;
    }
}
</style>
